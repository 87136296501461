<template>
  <div class="container">
    <table-page dataName="activities"
                ref="table"
                :tabList="tabList"
                :params="{type:'RESEARCH'}"
                :request="fetchActivies">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   @click="$router.push('setResearch')">添加活动</el-button>
      </template>

      <el-table-column label="活动图片">
        <template slot-scope="scope">
          <TableImg :url="scope.row.img_data ? scope.row.img_data.url :''" />
        </template>
      </el-table-column>
      <el-table-column label="活动模板"
                       show-overflow-tooltip=""
                       prop="template_type">
        <template slot-scope="scope">
          <span>{{scope.row.template_type ===  'SOCIAL_SECURITY_CARD'?'社保卡业务调研':''}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title"
                       show-overflow-tooltip
                       label="活动名称">
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="活动区域">
        <template slot-scope="scope">
          <span>{{`${scope.row.list_province || ''}${scope.row.list_city || ''}${scope.row.list_district || ''}${scope.row.list_net || ''}`}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="起止时间">
        <template slot-scope="scope">
          {{scope.row.started_at}}-{{scope.row.ended_at}}}
        </template>
      </el-table-column>
      <el-table-column label="活动状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 'NOW_PROCEED'">
            <el-badge is-dot
                      type="success"
                      class="mr-15"></el-badge>进行中
          </span>
          <span v-else-if="scope.row.status === 'NOT_PROCEED'">
            <el-badge is-dot
                      type="warning"
                      class="mr-15"></el-badge>未开始
          </span>
          <span v-else>
            <el-badge is-dot
                      type="danger"
                      class="mr-15"></el-badge>已结束
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="join_total"
                       show-overflow-tooltip
                       label="参与数">
      </el-table-column>
      <el-table-column prop="verify_total"
                       show-overflow-tooltip
                       label="核销数">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作"
                       width="200px">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="netManage(scope.row)">网点管理</el-button>
          <el-button type="text"
                     v-permission="['ADMIN']"
                     @click="$router.push({name:'SetResearch',query:{id:scope.row.id}})">编辑</el-button>
          <el-button type="text"
                     v-permission="['ADMIN']"
                     @click="delActivity(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </table-page>
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import TableImg from '@/components/TableImg'
import { fetchActivies, delActivity } from '@/api/market'
export default {
  name: 'Partner',
  data () {
    return {
      fetchActivies,
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'NOT_PROCEED',
        label: '未开始'
      }, {
        value: 'NOW_PROCEED',
        label: '进行中'
      }, {
        value: 'END_PROCEED',
        label: '已结束'
      }]
    }
  },
  components: {
    TablePage,
    TableImg
  },
  methods: {
    setTerrace (row) {
      this.$refs.form.showModel(row)
    },
    netManage (row) {
      this.$router.push({
        name: 'ResearchNets',
        query: {
          id: row.id
        }
      })
    },
    delActivity (id) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delActivity({
          activity_ids: [id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
